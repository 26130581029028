/*
 * Contains helper variables and functions for recurring springboard
 * calculations and constants.
 *
 * Before adding new things to this file, be sure to check that
 * Drupal.settings doesn't already have what you need. You can see
 * what's in it by typing `Drupal.settings` in the devtools console.
 * That object has sub-objects that contain a lot of useful values.
 */

(function ($) {
  const DONATION_RECURRING_QUERY = "input[type='radio'][name='submitted[donation][recurs_monthly]']";
  const DONATION_FREQUENCY_QUERY = 'input[type="radio"][name="submitted[donation][recurs_monthly]"]:checked';
  const PAYMENT_TYPES_QUERY = ".form-item-submitted-extra-fields-payment-options, input.apple-pay-button";
  const DONATION_TYPE_QUERY = "#webform-component-donation--recurs-monthly"

  function getOneTimeDonationRadioButton(){
    return $('#edit-submitted-donation-recurs-monthly input[type="radio"][value="NO_RECURR"]');
  }

  function getMonthlyDonationRadioButton(){
    return $('#edit-submitted-donation-recurs-monthly input[type="radio"][value="recurs"]');
  }

  function getAnnualDonationRadioButton() {
    return $('#edit-submitted-donation-recurs-monthly input[type="radio"][value="sb_fs_annually"]');
  }

  function isOneTimeDonationRadioButtonSelected(){
    return getOneTimeDonationRadioButton().is(':checked');
  }

  function isAnnualDonationRadioButtonSelected(){
    return getAnnualDonationRadioButton().is(':checked');
  }
  function isMonthlyDonationRadioButtonSelected(){
    return getMonthlyDonationRadioButton().is(':checked');
  }

  function getFrequencyRadioButtons() {
    return $(DONATION_RECURRING_QUERY);
  }

  function selectOneTimeDonationRadioButton() {
    return getOneTimeDonationRadioButton().click()
  }

  function selectAnnualDonationRadioButton() {
    return getAnnualDonationRadioButton().click()
  }

  function getDonationFrequency() {
    return $(
      DONATION_FREQUENCY_QUERY
    ).val()
  }

  module.exports = {
    ONE_TIME_FREQUENCY: "NO_RECURR",
    MONTHLY_FREQUENCY: "recurs",
    ANNUAL_FREQUENCY: "sb_fs_annually",
    DONATION_RECURRING_QUERY,
    DONATION_FREQUENCY_QUERY,
    PAYMENT_TYPES_QUERY,
    DONATION_TYPE_QUERY,
    getOneTimeDonationRadioButton,
    getMonthlyDonationRadioButton,
    getAnnualDonationRadioButton,
    isOneTimeDonationRadioButtonSelected,
    isMonthlyDonationRadioButtonSelected,
    isAnnualDonationRadioButtonSelected,
    getFrequencyRadioButtons,
    selectOneTimeDonationRadioButton,
    selectAnnualDonationRadioButton,
    getDonationFrequency,
  };
})(jQuery);
